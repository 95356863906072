import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import cuid from 'cuid'
import cookie from 'js-cookie'
import { LoaderAppData, NotificationAppData } from '@nogin/api/src/appdata/appearance'
import { DiscountAppSettings } from '@nogin/api/src/appdata/discounts'
import { AppConfig, ContextConfig, Customer, CustomerAccess, CustomerPreferences } from '../../types'
import CollectionSetting from '../../elements/CollectionSetting/models'
import DynamicPlacementModel from '../../elements/DynamicPlacement/models'
import { RootState } from '..'
import SnippetModel from '../../elements/Snippet/models'

const NOGIN_ACCESS_TOKEN_COOKIE_NAME = 'nogin_access_token_COOKIE_NAME'

/**
 * If the value of utmOfferKey is updated here,
 * also update the addonBefore for utm in packages/admin/src/elements/Offer/schema.json
 * so the user is aware of what url param to append to customer urls
 */
const UTM_OFFER_KEY = 'nogin_campaign'
const SUBJECT_ID_KEY = 'nogin_subject'

export interface DynamicPlacementModelWithSnippet extends DynamicPlacementModel {
  _snippet?: SnippetModel
}

interface AppState {
  ready: boolean
  config: AppConfig
  notifications: NotificationPayload[]
  settings: {
    notification?: NotificationAppData
    loader?: LoaderAppData
    discounts?: DiscountAppSettings
    filters?: CollectionSetting
  }
  accessToken?: string
  loading: boolean
  customer?: CustomerAccess
  isAdmin: boolean
  isPreview: boolean
  isSnapshotRender?: boolean
  site: {
    title?: string
    description?: string
    enableHeadlessCommerce?: boolean
  }
  debug: boolean
  placements: { [key: string]: DynamicPlacementModelWithSnippet }
  activePlacements: string[]
  storefront?: {
    url: string
    accessToken: string
  }
}

export interface NotificationPayload {
  type?: 'success' | 'error' | 'warning' | 'custom'
  title?: string
  subtitle?: string
  linkTitle?: string
  linkUrl?: string
  image?: string
  _id?: string
}

export interface EventPayload {
  source: string
  data?: any
}

export interface LoadingPayload {
  on?: boolean
}

const initialState: AppState = {
  ready: false,
  config: {
    publicToken: '',
    currency: {
      symbol: '$',
      precision: 2,
      thousands: ',',
      decimal: '.'
    }
  },
  notifications: [],
  settings: {},
  accessToken: '',
  customer: {
    id: '',
    email: '',
    hash: '',
    isRegistered: false,
    hasWishList: false,
    preferences: {}
  },
  loading: false,
  isAdmin: false,
  isPreview: false,
  isSnapshotRender: false,
  site: {
    title: '',
    description: '',
    enableHeadlessCommerce: false
  },
  debug: false,
  placements: {},
  activePlacements: [],
  storefront: {
    url: '',
    accessToken: ''
  }
}

/**
 * Loads accessToken from cookie or from /customers/connect endpoint
 */
export const connectCustomer = createAsyncThunk(
  'app/customers/connect',
  async ({ config }: { config: AppConfig }, { dispatch, rejectWithValue }): Promise<any> => {
    try {
      const accessToken = cookie.get(NOGIN_ACCESS_TOKEN_COOKIE_NAME)
      // add accesstoken to default params
      if (accessToken) {
        if (!axios.defaults.params) {
          axios.defaults.params = {}
        }
        axios.defaults.params.accessToken = accessToken
      }

      const response = await axios.post('/customers/connect', config.customer)

      let newAccessToken
      if (response.data?.accessToken) {
        newAccessToken = response.data?.accessToken as string

        // update default access token
        axios.defaults.params.accessToken = newAccessToken
        cookie.set(NOGIN_ACCESS_TOKEN_COOKIE_NAME, newAccessToken)
      } else {
        throw new Error('Could not load access token')
      }
      if (response.data?.customer?.hasWishList == false) {
        axios.defaults.headers.common['nogin-no-customer-lookup'] = 'no-wishlist'
      }

      const payload: {
        accessToken: string
        customer: any
      } = {
        accessToken: newAccessToken,
        customer: {
          ...config.customer,
          ...response.data?.customer,
          preferences: JSON.parse(localStorage.getItem('customer_preferences') || '{}')
        }
      }

      return payload
    } catch (e) {
      // invalid token remove cookie and clear axios defaults
      cookie.remove(NOGIN_ACCESS_TOKEN_COOKIE_NAME)
      axios.defaults.params.accessToken = ''

      // redispatch will generate new tokens
      dispatch(connectCustomer({ config }))
      return rejectWithValue('Could not connect customer')
    }
  }
)

export const storeSettings = createAsyncThunk(
  'app/stores/connect',
  async ({ config }: { config: AppConfig }, { dispatch, rejectWithValue }): Promise<any> => {
    try {
      const response = await axios.get(`/stores/connect`)
      const payload: {
        config: AppConfig
        wishlist: any
        settings: {
          notification: any
          loader: any
          discounts: any
          filters: any
        }
        site: {
          title?: string
          description?: string
        },
        storefront?: {
          url: string
          accessToken: string 
        }
      } = {
        config,
        wishlist: {},
        settings: {
          notification: response.data?.notification,
          loader: response.data?.loader,
          discounts: response.data?.discounts,
          filters: response.data?.filters
        },
        site: response?.data?.site,
        storefront: response?.data?.storefront
      }

      return payload
    } catch (e) {
      return rejectWithValue('Could not connect store settings')
    }
  }
)

export const loadPlacements = createAsyncThunk(
  'app/loadPlacements',
  async ({ previewId }: { previewId?: string }, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState
    const pageType = state.app.config.context?.pageType || 'all'

    const noginCampaign = new URLSearchParams(window.location.search)?.get(UTM_OFFER_KEY)

    if(noginCampaign){
      cookie.set(UTM_OFFER_KEY, noginCampaign)
    }

    const subjectId = cookie.get(SUBJECT_ID_KEY) ?? undefined

    // generate local context object and send with api call in the header
    // this allows us to add "rules" for each placement/snippet
    const context = encodeURI(JSON.stringify({
      ...state.app.config.context,
      pageType,
      url: window.location.pathname,
      urlSearchParams: window.location.search,
      noginCampaign: cookie.get(UTM_OFFER_KEY),
      subjectId
    }))

    // build filter conditions
    const condition = `pageType::equals::${pageType}`
    const conditions: string[] = []
    conditions.push(condition)
    if (pageType !== 'all') {
      conditions.push(`pageType::equals::all`)
    }

    try {
      const response = await axios.get('/element/data/dynamic-placement', {
        params: {
          pageSize: 100,
          conditions,
          matchType: 'match_any',
          previewId
        },
        headers: {
          'nogin-context': context
        }
      })

      const placementItems = response.data.items
      let placements: { [key: string]: DynamicPlacementModelWithSnippet } = {}

      placementItems.forEach((placement: any) => {
        let placementData = placement.data as DynamicPlacementModelWithSnippet
        placements[placementData.slug] = placementData
      })

      if (response.data.subjectId) {
        cookie.set(SUBJECT_ID_KEY, response.data.subjectId)
      }

      return placements
    } catch(e) {
      return rejectWithValue(e)
    }
  }
)

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    notification(state, { payload, type }: { payload: NotificationPayload; type: string }) {
      payload._id = cuid()
      state.notifications.push(payload)
    },
    removeNotification(state, action) {
      const newNotifications = state.notifications.filter((n) => n._id !== action.payload._id)
      state.notifications = newNotifications
    },
    toggleLoading(state, action) {
      state.loading = action.payload !== undefined ? action.payload : !state.loading
    },
    sendEvent(state, { payload, type }: { payload: EventPayload; type: string }) {
      // do nothing
    },
    updateContext(state, { payload, type }: { payload: ContextConfig; type: string }) {
      state.config.context = {
        ...state.config.context,
        ...payload
      }
    },
    toggleAdmin(state, action: PayloadAction<boolean>) {
      state.isAdmin = action.payload
    },
    hasWishList(state, action: PayloadAction<boolean>) {
      if (state.customer) {
        state.customer.hasWishList = action.payload
      }
    },
    setCustomerPreferences(state, { payload, type }: { payload: CustomerPreferences, type: string }) {
      if(state.customer) {
        state.customer.preferences = { 
          ...state.customer.preferences,
          ...payload
        }
        localStorage.setItem('customer_preferences', JSON.stringify(state.customer.preferences))
      }
    },
    updateActivePlacements(state, action: PayloadAction<string[]>) {
      state.activePlacements = action.payload
    },
    debug(state, action: PayloadAction<boolean>) {
      state.debug = action.payload
    },
    togglePreview(state, action: PayloadAction<string | null | undefined>) {
      if (action.payload) {
        cookie.set('__nogin_pid', action.payload)
        state.isPreview = true
      } else {
        cookie.remove('__nogin_pid')
        state.isPreview = false

        const searchParams = new URLSearchParams(window.location.search)

        if (searchParams.has('nogin_pid')) {
          searchParams.delete('nogin_pid')
          const newUrl =  window.location.pathname + '?' + searchParams.toString()
          window.location.href = newUrl
        }
      }
    },
    toggleIsSnapshotRender(state, action: PayloadAction<boolean>) {
      state.isSnapshotRender = action.payload
    },
  },
  
  extraReducers: (builder) => {
    builder.addCase(storeSettings.fulfilled, (state, action) => {
      const payload = action.payload as any
      state.storefront = payload.storefront
      state.config = payload.config
      state.settings = payload.settings
      state.site = payload.site
    })

    builder.addCase(connectCustomer.fulfilled, (state, action) => {
      const payload = action.payload as any
      state.accessToken = payload.accessToken
      state.customer = payload.customer
      state.ready = true
    })

    builder.addCase(connectCustomer.rejected, (state, action) => {
      // TODO: handle error access
    })

    builder.addCase(loadPlacements.fulfilled, (state, action) => {
      const payload = action.payload
      state.placements = payload
    })
  }
})

export const {
  notification,
  removeNotification,
  toggleLoading,
  sendEvent,
  updateContext,
  toggleAdmin,
  hasWishList,
  setCustomerPreferences,
  togglePreview,
  toggleIsSnapshotRender,
  updateActivePlacements,
  debug
} = appSlice.actions
export default appSlice.reducer
