import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
import { RootState } from '..'
import { objectToInitialState } from '../../lib/helpers'
import {
  WishListAddActionArgs,
  WishListCustomerItemPayload,
  WishListCustomerListBulkUpdatePayload,
  WishListCustomerListPayload,
  WishListState,
  CustomerAccess,
} from '../../types'
import {
  WishListManagerAppData,
  WishListLauncherAppData,
  WishListNotificationAppData
} from '../../types'
import { toggleLoading, sendEvent, notification, hasWishList } from './app'

const initialState: WishListState = {
  ready: false,
  settings: {
    manager: objectToInitialState(WishListManagerAppData),
    launcher: objectToInitialState(WishListLauncherAppData),
    notification: objectToInitialState(WishListNotificationAppData),
    addButtons: [],
    listItemTotal: 0
  },
  items: [],
  productIds: [],
  elementIds: [],
  customer: {
    listLoading: false,
    lists: [],
    currentListItemLoading: false,
    currentListItems: [],
    currentListItemTotal: 0,
    currentListId: '',
    currentListPageIndex: 1,
    listSortBy: 'created-desc',
    totalCount: 0,
    sharedList: undefined,
    session: {
      productVotes: {},
      elementVotes: {},
    }
  },

}

/**
 * Retrieve alls settings for wish list app
 */
export const getWishListSettings = createAsyncThunk(
  'wishlist/getSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/wishlist/settings')
      return response.data
    } catch (e) {
      const err = e as AxiosError
      console.log(err.response?.data)
      return rejectWithValue(err.response?.data)
    }
  }
)

/**
 * Adds item to customers list
 */
export const addWishListItem = createAsyncThunk(
  'wishlist/addItem',
  async (
    {
      elementKey,
      elementLabel,
      productId,
      productVariantId,
      elementId,
      score,
      response
    }: WishListAddActionArgs,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      //dispatch(toggleLoading(true))
      let eventPayload = {}
      let requestPayload = {}

      eventPayload = { productId, elementId, score, response }

      requestPayload = {
        sourceProductId: productId,
        sourceProductVariantId: productVariantId,
        elementKey,
        elementLabel,
        elementId,
        score,
        response,
        voteMethod: 'up_down'
      }

      const state = getState() as RootState

      const apiResponse = await axios.post('/customers/wishlist/items', requestPayload)

      // api returns add/remove
      const toggle = apiResponse.data.msg

      // dispatch event for widget integration
      dispatch(sendEvent({ source: `wishlist.item.${toggle}`, data: eventPayload }))

      // reload customer wishlist
      const currentListId = state.wishlist.customer.currentListId

      if (currentListId) {
        dispatch(getCustomerWishListItemsForList({ listId: currentListId as string, pageIndex: 1 }))
      }
      
      if (state?.app?.customer?.hasWishList == false) {
        dispatch(hasWishList(true))
      }

      return {...eventPayload, ...apiResponse.data}

    } catch (e) {
      console.log(e)
    }
  }
)

/**
 * Gets all customer lists
 */
export const getCustomerWishLists = createAsyncThunk(
  'wishlist/getCustomerWishLists',
  async (_, { rejectWithValue, dispatch, getState }): Promise<any> => {
    try {
      dispatch(toggleLoading(true))

      const state = getState() as RootState
      const wishListState = state.wishlist as WishListState

      const response = await axios.get('/customers/wishlist')
      const lists = response.data as WishListCustomerListPayload[]

      if (wishListState.customer.currentListId === '' && lists.length > 0) {
        // dispatch getWishListitems for first item
        const currentList = lists[0]
        dispatch(getCustomerWishListItemsForList({ listId: currentList.id, pageIndex: 1 }))
      }

      return lists
    } catch (e) {
      console.log(e)
      return rejectWithValue('Could not retrieve customer wish lists')
    }
  }
)

/**
 * Gets wishlist using share code
 */
export const getWishListUsingCode = createAsyncThunk(
  'wishlist/getWishListUsingCode',
  async ({ code }: { code: string }, { rejectWithValue, dispatch, getState }): Promise<any> => {
    try {
      const response = await axios.get(`/customers/wishlist/${code}`, {
        params: {
          idType: 'code'
        }
      })
      const sharedList = response.data as WishListCustomerListPayload
      dispatch(getCustomerWishListItemsForList({ pageIndex: 1, shareCode: code }))
      return sharedList
    } catch (e) {
      console.log(e)
      return rejectWithValue('Could not retrieve wish lists')
    }
  }
)

/**
 * Gets wish list items for an array of product/element ids
 */
export const getWishListItems = createAsyncThunk(
  'wishlist/getWishListItems',
  async ({ productIds, elementIds }: { productIds?: string[]; elementIds?: string[] }) => {
    try {
      let params = {}

      if (productIds?.length) {
        params = { sourceProductIds: productIds }
      } else if (elementIds?.length) {
        params = { elementIds }
      }

      const response = await axios.get('/wishlist/items', {
        params
      })

      return {
        items: response.data,
        productIds,
        elementIds
      }
    } catch (e) {
      console.log(e)
    }
  }
)

/**
 * Get a customer's wish list items for a given wishlist id
 */
export const getCustomerWishListItemsForList = createAsyncThunk(
  'wishlist/getCustomerWishListItemsForList',
  async (
    { listId, pageIndex, shareCode }: { listId?: string; pageIndex: number; shareCode?: string },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState
    const currentItemState = state.wishlist.customer

    try {
      dispatch(toggleLoading(true))

      let response

      if (shareCode) {
        response = await axios.get(`/customers/wishlist/${shareCode}/items`, {
          params: {
            idType: 'code',
            pageIndex,
            pageSize: state.wishlist.settings.manager.itemsPerPage,
            sortBy: currentItemState.listSortBy
          }
        })
      } else {
        response = await axios.get(`/customers/wishlist/${listId}/items`, {
          params: {
            pageIndex,
            pageSize: state.wishlist.settings.manager.itemsPerPage,
            sortBy: currentItemState.listSortBy
          }
        })
      }

      const newItems = response.data.items as WishListCustomerItemPayload[]
      let nextItems = []

      if (pageIndex === 1) {
        // reset state item list
        nextItems = newItems
      } else {
        // add to existing list for pagination
        nextItems = currentItemState.currentListItems.concat(newItems)
      }

      dispatch(toggleLoading(false))

      return {
        pageIndex,
        currentListId: listId,
        items: nextItems,
        total: response.data.total
      }
    } catch (e) {
      console.log(e)
    }
  }
)

/**
 * Remove a customers wish list item
 */
export const removeCustomerWishListItem = createAsyncThunk(
  'wishlist/removeCustomerWishListItem',
  async (
    { listId, itemId }: { listId: string; itemId: string },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const response = await axios.delete(`/customers/wishlist/${listId}/items/${itemId}`)
      dispatch(getCustomerWishListItemsForList({ listId, pageIndex: 1 }))
      dispatch(getWishListSettings())

      const state = getState() as RootState
      const item = state.wishlist.customer.currentListItems.find((i: any) => i.id === itemId)
      dispatch(
        sendEvent({ source: 'wishlist.item.remove', data: { productId: item?.product.sourceId } })
      )
    } catch (e) {
      console.log(e)
    }
  }
)

/**
 * Reorders position of customer list
 */
export const changeCustomerWishListPosition = createAsyncThunk(
  'wishlist/changeCustomerWishListPosition',
  async (
    { oldIndex, newIndex }: { oldIndex: number; newIndex: number },
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as RootState

    // track which lists need updating
    const updated: WishListCustomerListBulkUpdatePayload[] = []

    // clone list
    let newList = [...state.wishlist.customer.lists]

    // drag item forward
    let startIndex = oldIndex
    let endIndex = newIndex + 1
    let inc = -1

    // drag item backward
    if (newIndex < oldIndex) {
      startIndex = newIndex
      endIndex = oldIndex
      inc = 1
    }

    // update position of affected items
    for (let i = startIndex; i < endIndex; i++) {
      newList[i] = { ...newList[i], position: newList[i].position + inc }
      updated.push({
        id: newList[i].id,
        name: newList[i].name,
        position: newList[i].position,
        visibility: newList[i].visibility
      })
    }

    // update position of item being dragged
    newList[oldIndex] = { ...newList[oldIndex], position: newIndex + 1 }
    updated.push({
      id: newList[oldIndex].id,
      name: newList[oldIndex].name,
      position: newList[oldIndex].position,
      visibility: newList[oldIndex].visibility
    })

    // sort by position
    newList = newList.sort((a, b) => a.position - b.position)

    // batch update
    async function update() {
      const response = await axios.put('/customers/wishlist/batch', {
        items: updated
      })
    }

    // trigger update without waiting to achieve
    // faster ui response
    update()

    return newList
  }
)

/**
 * Moves a customers wishlist item to another list
 */
interface moveWishListItemProps {
  itemId: string
  listId: string
}

export const moveWishListItem = createAsyncThunk(
  'wishlist/moveWishListItem',
  async ({ itemId, listId }: moveWishListItemProps, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState
      const response = await axios.put(`/customers/wishlist/items/${itemId}`, {
        wishListId: listId
      })

      dispatch(getCustomerWishLists())
      dispatch(
        getCustomerWishListItemsForList({
          listId: state.wishlist.customer.currentListId as string,
          pageIndex: 1
        })
      )
    } catch (e) {
      const err = e as AxiosError
      console.log(err.response?.data)
    }
  }
)

/**
 * Deletes a customers list
 *
 * @param listId
 */
interface deleteCustomerWishListProps {
  id: string
}

export const deleteCustomerWishList = createAsyncThunk(
  'wishlist/deleteCustomerWishList',
  async ({ id }: deleteCustomerWishListProps, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState
      await axios.delete(`/customers/wishlist/${id}`)

      dispatch(getCustomerWishLists())

      if (state.wishlist.customer.lists.length > 0) {
        const firstList = state.wishlist.customer.lists[0]
        // select first list
        dispatch(getCustomerWishListItemsForList({ listId: firstList.id, pageIndex: 1 }))
      }
    } catch (e) {
      const err = e as AxiosError
      console.log(err.response?.data)
    }
  }
)

interface addWishListItemToCartProps {
  item: WishListCustomerItemPayload
  variant: any
}

export const addWishListItemToCart = createAsyncThunk(
  'wishlist/addWishListItemToCart',
  async ({ item, variant }: addWishListItemToCartProps, { dispatch, rejectWithValue }) => {
    const data = {
      id: +variant.sourceId,
      quantity: 1,
      properties: {
        '__Nogin Wish Item': item.id
      }
    }

    dispatch(toggleLoading(true))

    // @ts-ignore check if running on shopify site
    if (typeof Shopify !== 'undefined') {
      try {
        // create axios client without a base url to call
        // shopify api when embedded in client site
        const shopifyClient = axios.create({
          baseURL: '',
          params: {},
          timeout: 3000
        })
        await shopifyClient.post('/cart/add.js', data)
      } catch (e) {
        console.log(e)
        dispatch(notification({ type: 'error', title: 'Could not add item to cart' }))
      }
    } else {
      console.log('DEBUG: currently not running on live site.')
    }

    // update item purchasedStatus to inCart and save current price, and variantId
    const updateParams = {
      sourceProductVariantId: variant.sourceId,
      productVariantId: variant.id,
      purchasedStatus: 'inCart',
      purchasedPrice: variant.priceNumeric,
      purchasedOn: new Date().toISOString()
    }

    try {
      await axios.put(`/customers/wishlist/items/${item.id}`, updateParams)
    } catch (e) {
      console.log(e)
    }
    dispatch(sendEvent({ source: `wishlist.addtocart`, data: { product: item.product } }))

    dispatch(toggleLoading(false))
  }
)

/**
 * Create slice
 */
const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    updateItems: (state, action) => {
      // assume success and update count
      const itemIndex = state.items.findIndex((item) => {
        if (action.payload?.productId) {
          return item?.sourceProductId === action.payload.productId
        } else if (action.payload?.elementId) {
          return item?.elementId === action.payload.elementId
        }
      })
      let item = state.items[itemIndex]

      if (item) {
        if (item.customerWished === 0) {
          // If customer neither up nor downvoted on this, record new vote
          item.customerWished = action.payload.score
        } else {
          // Change item totals to reflect removing old vote
          if (item.customerWished === 1 && item.totalWishedUp > 0) {
            item.totalWishedUp--
          } else if (item.customerWished === -1 && item.totalWishedDown > 0) {
            item.totalWishedDown--
          }
          if (item.customerWished == action.payload.score) {
            // If new vote is same as existing, we are removing the vote
            item.customerWished = 0
          } else {
            // otherwise, we're recording the new vote
            item.customerWished = action.payload.score
          }
        }

        if (item.customerWished == 1) {
          item.totalWishedUp++
        } else if (item.customerWished == -1) {
          item.totalWishedDown++
        }
      }

      state.items.splice(itemIndex, 1, item)
    },
    updateSortBy: (state, action) => {
      state.customer.listSortBy = action.payload
    }
  },
  extraReducers: (builder) => {
    /**
     * getWishListSettings
     */
    builder.addCase(getWishListSettings.pending, (state, action) => {
      state.ready = false
    })
    builder.addCase(getWishListSettings.fulfilled, (state, action) => {
      state.settings = action.payload
      state.ready = true
    })
    builder.addCase(getWishListSettings.rejected, (state, action) => {
      state.ready = false
    })
    /**
     * getWishListItems
     */
    builder.addCase(getWishListItems.fulfilled, (state, action) => {
      if (action.payload) {
        state.items = action.payload?.items
        state.productIds = action.payload?.productIds as string[]
        state.elementIds = action.payload?.elementIds as string[]
      }
    })
    builder.addCase(getWishListItems.rejected, (state, action) => {
      state.items = []
    })
    /**
     * getCustomerWishLists
     */
    builder.addCase(getCustomerWishLists.pending, (state, action) => {
      state.customer.listLoading = true
    })
    builder.addCase(getCustomerWishLists.fulfilled, (state, action) => {
      state.customer.lists = action.payload as WishListCustomerListPayload[]
      //state.customer.listLoading = false
    })
    builder.addCase(getCustomerWishLists.rejected, (state, action) => {
      state.customer.listLoading = false
    })
    /**
     * getWishListUsingCode
     */
    builder.addCase(getWishListUsingCode.pending, (state, action) => {
      state.customer.listLoading = true
    })
    builder.addCase(getWishListUsingCode.fulfilled, (state, action) => {
      state.customer.sharedList = action.payload as WishListCustomerListPayload
      state.customer.listLoading = false
    })
    builder.addCase(getWishListUsingCode.rejected, (state, action) => {
      state.customer.listLoading = false
    })
    /**
     * getCustomerWishListItemsForList
     */
    builder.addCase(getCustomerWishListItemsForList.pending, (state, action) => {
      state.customer.currentListItemLoading = true
    })
    builder.addCase(getCustomerWishListItemsForList.fulfilled, (state, action) => {
      state.customer.currentListPageIndex = action.payload?.pageIndex || 0
      state.customer.currentListId = action.payload?.currentListId || ''
      state.customer.currentListItems = action.payload?.items || []
      state.customer.currentListItemTotal = action.payload?.total || 0
      state.customer.currentListItemLoading = false
    })
    builder.addCase(getCustomerWishListItemsForList.rejected, (state, action) => {
      state.customer.currentListItemLoading = false
    })
    /**
     * removeCustomerWishListItem
     */
    builder.addCase(removeCustomerWishListItem.pending, (state, action) => {
      state.customer.currentListItemLoading = true
    })
    builder.addCase(removeCustomerWishListItem.fulfilled, (state, action) => {})
    builder.addCase(removeCustomerWishListItem.rejected, (state, action) => {
      state.customer.currentListItemLoading = false
    })
    /**
     * removeCustomerWishListItem
     */
    builder.addCase(changeCustomerWishListPosition.pending, (state, action) => {}),
      builder.addCase(changeCustomerWishListPosition.fulfilled, (state, action) => {
        state.customer.lists = action.payload
      })
    builder.addCase(changeCustomerWishListPosition.rejected, (state, action) => {})
    builder.addCase(addWishListItem.fulfilled, (state, action) => {
      action.payload?.msg === 'add' ? state.settings.listItemTotal++ : state.settings.listItemTotal--
      if(action.payload?.elementId){
        state.customer.session.elementVotes[action.payload?.elementId] = action.payload
      }
      if(action.payload?.productId){
        state.customer.session.productVotes[action.payload?.productId] = action.payload
      }
    })
  }
})

export const { updateSortBy, updateItems } = wishlistSlice.actions
export default wishlistSlice.reducer
