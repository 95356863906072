import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios, { CancelTokenSource, AxiosError } from 'axios'
import { RootState } from '..'
import queryString from 'query-string'
import { sendEvent, toggleLoading } from './app'
import CollectionFilterModel from '../../elements/CollectionFilter/models'
import CollectionModel from '../../elements/Collection/models'
import { IntlPrices } from '../../types'
import { getInternationalPrices } from '../../lib/helpers'
import CollectionInlineBannerModel from '../../elements/CollectionInlineBanner/models'

interface CollectionState {
  isLoaded: boolean
  params: { [key: string]: string[] }
  filters: CollectionFilterModel[]
  config: any
  sortBy: string
  search: string
  isSearchCleared: boolean
  baseCollection: string
  pageIndex: number
  pageSize: number
  inlineBannerArray: CollectionInlineBannerModel[]
  isMobile: boolean
  products: any[]
  intlPrices: IntlPrices[]
  totalProducts: number
  content: any
  facetedData: any
  incomingQueryType: string
  currentCollection?: CollectionModel
  isQuickBuyOpen?: boolean
  outOfStock: boolean
  isSwatchListFiltered: boolean
  lastOpenPicker: string
  infiniteScrollPageIndex: number
  minReviewsAverage: number
  collectionLinks: Record<string, string[]>
}

interface LoadThunkParams {
  productIds?: string[]
  hideLoadingIndicator?: boolean
}

const initialState: CollectionState = {
  isLoaded: false,
  params: {},
  config: {},
  filters: [],
  sortBy: '',
  search: '',
  isSearchCleared: false,
  baseCollection: '',
  pageIndex: 1,
  pageSize: 36,
  inlineBannerArray: [],
  isMobile: false,
  // @ts-ignore
  products: typeof __NOGIN_INITIAL_PRODUCTS__ !== 'undefined' ? __NOGIN_INITIAL_PRODUCTS__ : [],
  intlPrices: [],
  totalProducts: 0,
  content: null,
  facetedData: null,
  incomingQueryType: '',
  currentCollection: undefined,
  isQuickBuyOpen: false,
  outOfStock: false,
  isSwatchListFiltered: false,
  lastOpenPicker: '',
  infiniteScrollPageIndex: 1,
  minReviewsAverage: 0,
  collectionLinks: {}
}

let cancelToken: CancelTokenSource | null = null

/**
 * Handles loading collection data into redux
 */
export const load = createAsyncThunk(
  'collectionElement/load',
  async (
    { productIds, hideLoadingIndicator = false }: LoadThunkParams,
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as RootState
    const filters = state.collectionElement.filters
    const search = state.collectionElement.search

    if (cancelToken) {
      // cancel previous requests
      cancelToken.cancel()
    }

    if (!hideLoadingIndicator) {
      dispatch(toggleLoading(true))
    }
    cancelToken = axios.CancelToken.source()

    const filterState: {
      options: string[]
      tags: string[]
      prices: string[]
      mappedTagOptions: string[]
      metaFields: { [key: string]: string[] }
    } = {
      options: [],
      tags: [],
      prices: [],
      mappedTagOptions: [],
      metaFields: {}
    }

    Object.entries(state.collectionElement.params).forEach(([key, value]) => {
      try {
        // get filter config for key
        const filterConfig = filters.filter((f) => f.name === key)
        if (filterConfig.length > 0) {
          switch (filterConfig[0].filterType) {
            case 'tag':
              filterState.tags = [...filterState.tags, ...value.map((v) => `${key}:${v}`)]
              if (filterConfig[0].isTagMappedToOption && filterConfig[0].mapToOption) {
                const optionTagValues = value.map((v) => {
                  const tagSplitParts = v.split(':')
                  return `${filterConfig[0].mapToOption}:${tagSplitParts[tagSplitParts.length - 1]}`
                })
                filterState.mappedTagOptions = [...filterState.mappedTagOptions, ...optionTagValues]
              }
              break
            case 'option':
              // option needs key:value pair
              filterState.options = [...filterState.options, ...value.map((v) => `${key}:${v}`)]
              break
            case 'price':
              filterState.prices = [...filterState.prices, ...value]
              break
            case 'reviews':
              const metaFieldKey = filterConfig[0].reviewsProperty
              filterState.metaFields = {
                ...filterState.metaFields,
                [metaFieldKey]: [...(filterState.metaFields[metaFieldKey] || []), ...value]
              }
              break
            default:
              break
          }
        }
      } catch (e) {
        rejectWithValue(`Error parsing filters: ${e}`)
      }
    })

    try {
      const searchTerm = search.trim() !== '' ? search : undefined

      //If a collection is default set to relevancy, it should use that as default when search term is provided
      //This is so /search page filters by relevance by default
      const relevanceSearch =
        searchTerm && state.collectionElement.currentCollection?.sortBy === 'relevance'
          ? 'relevance'
          : undefined

      //This is the "Default Sort" the IC user selected in the settings of the collection
      //The value is not used if it is set to "manual"
      //because there are other possible default options to try
      //before using "manual" which is either the way it was ordered by the Collection Merchandiser
      //or the way the API arbitrarily orders products
      const defaultSortByColletionSetting =
        state.collectionElement.currentCollection?.sortBy !== 'manual'
          ? state.collectionElement.currentCollection?.sortBy
          : undefined

      //Uses the sorterFilter to select the first sorter option because it is automatically selected by the sorter dropdown
      //This makes it so that the collection reflects the automically selected sort order
      const dropdownSorter = filters?.find(
        (filter) => filter.filterType === 'sorter' && filter.displayType === 'dropdown'
      )
      //The relevance sorter is manually added in packages/widget2/src/elements/CollectionFilter/components/SortPicker.tsx
      //if there is a search query. Here, the relevance sorter is added to the list of sorters so that it is also the default
      //if no sorters are chosen
      const sorterFilter: any =
        searchTerm && dropdownSorter
          ? {
              ...dropdownSorter,
              sortOptions: [
                {
                  type: 'relevance',
                  title: 'Relevance'
                },
                ...dropdownSorter.sortOptions
              ]
            }
          : {}

      let facets: string[] = []
      filters.forEach((filter) => {
        if (filter.filterType === 'tag') {
          if (filter.mode === 'automatic' && filter.tagPrefix) {
            facets.push(filter.tagPrefix)
          }
        }
      })

      if (state.collectionElement.minReviewsAverage > 0) {
        //Set the default filter state of the reviews average to the minimum reviews average
        //This gets overwritten when a filter is selected for reviews average so the filter
        //always works correctly
        if (filterState?.metaFields && !filterState?.metaFields?.reviews_average) {
          filterState.metaFields = {
            reviews_average: [`${state.collectionElement.minReviewsAverage}`]
          }
        }
      }

      const allProductsPath = '/products/es/search'
      const selectedProductsPath = '/products/es/search-selection'
      const path = productIds ? selectedProductsPath : allProductsPath
      const response = await axios.get(path, {
        params: {
          s: searchTerm,
          ...filterState,
          baseCollection: state.collectionElement.baseCollection,
          pageIndex: state.collectionElement.pageIndex,
          // Use the sortBy option the user selected (state.collectionElement.sortBy),
          // or sort by relevance if applicable
          // or the "Default Sort" from IC collection settings
          // or pick the first sort option in the list or dropdown
          // or sort the products according to Collection Merchandiser / arbitrary order returned from the API ("manual")
          sortBy:
            state.collectionElement.sortBy ||
            relevanceSearch ||
            defaultSortByColletionSetting ||
            sorterFilter?.sortOptions?.[0]?.type ||
            'manual',
          pageSize: state.collectionElement.pageSize,
          inlineBannerArray : state.collectionElement.inlineBannerArray,
          isMobile : state.collectionElement.isMobile,
          faceted: true,
          facetedTagSet: facets,
          outOfStock: state.collectionElement.outOfStock,
          productIds,
          isSwatchListFiltered: state.collectionElement.isSwatchListFiltered,
          incomingQueryType: state.collectionElement.incomingQueryType
        },
        cancelToken: cancelToken.token
      })
      cancelToken = null

      if (!hideLoadingIndicator) {
        dispatch(toggleLoading(false))
      }

      const iso: string = state.app.config.currency?.isoCountryCode ?? ''
      if (iso && iso != 'US') {
        const storeToken: string = state.app.config.shopify?.storefront?.token ?? ''
        const shopUrl: string = state.app.config.shopify?.storefront?.shopUrl ?? ''
        //get product ids for every product (to send to graphql)
        const gidPids: string[] = response?.data?.items?.map(
          (product: any) => `gid://shopify/Product/${product.sourceId}`
        )
        //eliminate duplicate product id's, if any
        let uniqueGidPids: Set<string> = new Set(gidPids)
        //add unqiue product id's for every variant (family)
        response?.data?.items?.forEach((product: any) => {
          product?.family?.map((item: any) => {
            uniqueGidPids.add(`gid://shopify/Product/${item.sourceId}`)
          })
        })
        //attach international prices for all unqiue product id's to results
        response.data.intlPrices = await getInternationalPrices(
          Array.from(uniqueGidPids),
          iso,
          storeToken,
          shopUrl
        )
        console.log(`retrieved latest iso:${iso} pricing.`)
      }

      return response.data
    } catch (e) {
      rejectWithValue('Could not get collection data')
    }
  }
)

/**
 * Resets entire store
 */
export const resetFilters = createAsyncThunk(
  'collectionElement/resetFilters',
  async (_, { dispatch }) => {
    const locationParts = window.location.href.split('?')
    if (locationParts.length > 1) {
      window.history.pushState([], '', locationParts[0])
      dispatch(sendEvent({ source: `catalog.filter.reset` }))
      return true
    }

    return false
  }
)

export const setFilters = createAsyncThunk(
  'collectionElement/setFilters',
  async ({ filter, items }: { filter: any; items: any }, { getState, dispatch }) => {
    const state = getState() as RootState

    dispatch(setPageIndex(1))

    if (items.length > 0) {
      dispatch(
        sendEvent({
          source: `catalog.filter.select`,
          data: {
            filter,
            selection: items,
            totalProducts: state.collectionElement.totalProducts
          }
        })
      )
    }

    let paramName = filter.name
    if (filter.filterType === 'sorter') {
      paramName = 'sortBy'
      dispatch(setSortBy(items))
    }

    const newParam = {
      ...state.collectionElement.params,
      [paramName]: items
    }

    // update browser history
    const qs = queryString.stringify(newParam, { arrayFormat: 'bracket' })
    window.history.pushState(newParam, '', window.location.href.split('?')[0] + (qs && '?' + qs))
  }
)

export const loadCollectionLinks = createAsyncThunk(
  'collectionLinks/loadCollectionLinks',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const path = 'collections/metadata/links'
      const response = await axios.get(path)
      return response.data
    } catch (e) {
      const ex = e as AxiosError
      if (ex.message) {
        console.log(e)
      }
      return rejectWithValue('Links not found')
    }
  }
)

const collectionSlice = createSlice({
  name: 'collectionElement',
  initialState,
  reducers: {
    setCurrentCollection: (state, action: PayloadAction<CollectionModel>) => {
      state.currentCollection = action.payload
    },
    setFilterOptions: (state, action: PayloadAction<CollectionFilterModel[]>) => {
      // remove duplicate filters
      const activeFilters = state.filters.map((filter) => filter.name)
      const newFilters = action.payload.filter((filter) => !activeFilters.includes(filter.name))
      state.filters = state.filters.concat(newFilters)
    },
    setParams: (state, action: PayloadAction<{ [key: string]: string[] }>) => {
      state.params = action.payload
    },
    setConfig: (state, action: PayloadAction<any>) => {
      state.config = action.payload
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setIsSearchCleared: (state, action: PayloadAction<boolean>) => {
      state.isSearchCleared = action.payload
    },
    setBaseCollection: (state, action: PayloadAction<string>) => {
      state.baseCollection = action.payload
    },
    setPageIndex: (state, action: PayloadAction<number>) => {
      state.pageIndex = action.payload
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setInlineBannerArray: (state, action: PayloadAction<CollectionInlineBannerModel[]>) => {
      state.inlineBannerArray = action.payload
    },
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload
    },
    setIsQuickBuyOpen: (state, action: PayloadAction<boolean>) => {
      state.isQuickBuyOpen = action.payload
    },
    setOutOfStock: (state, action: PayloadAction<boolean>) => {
      state.outOfStock = action.payload
    },
    setIntlPrices: (state, action: PayloadAction<IntlPrices[]>) => {
      state.intlPrices = action.payload
    },
    setIsSwatchListFiltered: (state, action: PayloadAction<boolean>) => {
      state.isSwatchListFiltered = action.payload
    },
    setLastOpenPicker: (state, action: PayloadAction<string>) => {
      state.lastOpenPicker = action.payload
    },
    setInfiniteScrollPageIndex: (state, action: PayloadAction<number>) => {
      state.infiniteScrollPageIndex = action.payload
    },
    setMinReviewsAverage: (state, action: PayloadAction<number>) => {
      state.minReviewsAverage = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(load.pending, (state, action) => {
      state.isLoaded = false
    })
    builder.addCase(load.fulfilled, (state, action) => {
      if (action.payload?.items) {
        state.products = action.payload.items
        state.intlPrices = action.payload.intlPrices
        state.totalProducts = action.payload.total
        state.content = action.payload.content
        state.facetedData = action.payload.facetedData
        state.incomingQueryType = action.payload.outgoingQueryType
        state.isLoaded = true
      }
    })
    builder.addCase(load.rejected, (state, action) => {
      state.isLoaded = true
    })
    builder.addCase(resetFilters.fulfilled, (state, action) => {
      if (action.payload) {
        state.facetedData = initialState.facetedData
        state.products = initialState.products
        state.pageIndex = initialState.pageIndex
        state.content = initialState.content
        state.params = initialState.params
        state.search = initialState.search
        state.sortBy = initialState.sortBy
        state.incomingQueryType = initialState.incomingQueryType
        state.totalProducts = initialState.totalProducts
      }
    })
    builder.addCase(loadCollectionLinks.fulfilled, (state, action: PayloadAction<Record<string, string[]>>) => {
      state.collectionLinks = action.payload
    })
  }
})

export const {
  setCurrentCollection,
  setFilterOptions,
  setParams,
  setConfig,
  setSortBy,
  setSearch,
  setIsSearchCleared,
  setBaseCollection,
  setPageIndex,
  setPageSize,
  setInlineBannerArray,
  setIsMobile,
  setIsQuickBuyOpen,
  setOutOfStock,
  setIntlPrices,
  setIsSwatchListFiltered,
  setLastOpenPicker,
  setInfiniteScrollPageIndex,
  setMinReviewsAverage
} = collectionSlice.actions
export default collectionSlice.reducer
